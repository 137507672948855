<template>
  <div @mousedown='mapMouseDown' @mouseup='mapMouseUp'>
    <baidu-map @dragend="mapViewChange" class="bm-view" auto-resize :min-zoom='11' :center="center" :zoom="zoom"
      @ready="handler" @zoomend='setZoom' :dragging='!showDraw' @mousemove="drawMap">
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
      <bm-control>
        <div v-if="loading" class="loading row c_center">
          <i style="color:#00ae66;" class="el-icon-loading"></i>
        </div>
        <div style="position:absolute;top:30px;left:30px;">
          <!-- 城市 交易 搜索框 -->
          <div class='title-width flex s_center' style="background-color: #ffffff;" @mouseover="mapMouseout"
            @mouseout="mapMouseover">
            <router-link to="/">
              <img class="logo" :src='whiteLogo' />
            </router-link>
            <p class="mh10 pl20 city-name" style="width:125px;" @click="doShow('showCity')">
              {{ center.city }}
              <i v-if="!canShow.showCity" class="el-icon-caret-bottom"></i>
              <i v-if="canShow.showCity" class="el-icon-caret-top"></i>
            </p>
            <p class="mh10" style="width:125px;" @click="doShow('showTrade')">
              {{ currentType.name }}
              <i v-if="!canShow.showTrade" class="el-icon-caret-bottom"></i>
              <i v-if="canShow.showTrade" class="el-icon-caret-top"></i>
            </p>
            <input placeholder="请输入您的目标地点" class="mr10 input" v-model="searchKey" @input="doSearch" />
            <i v-if="!searchKey" class="el-icon-search" @click="doSearch"></i>
            <i v-if="searchKey" class="el-icon-circle-close" @click="clearSearch"></i>
          </div>

          <!-- 搜索列表 -->
          <div v-if="canShow.showSearch" class="house-list city-list" @mouseover="mapMouseout" @mouseout="mapMouseover">
            <ul class="ph20 size16">
              <li class="pv10 row sb_center" v-for="(item) in region_5" :key='item.uuid' @click="clickSearch(item)">
                <p>
                  <span v-for="(ele, index) in item.name.split('')" :key="index"
                    :style="{ color: searchKey.indexOf(ele) > -1 ? '#00ae66' : '#000000' }">{{ ele }}</span>
                </p>
                <p style="color: #999;">
                  在{{ currentType.value === 2 ? "售" + item.saleCount : "租" + item.rentCount }}套
                </p>
              </li>
            </ul>
            <div class="m20" />
          </div>

          <!-- 城市列表 -->
          <div v-if="canShow.showCity" class="house-list city-list city-list-cityCard " @mouseover="mapMouseout"
            @mouseout="mapMouseover">
            <ul>
              <li v-for="(item) in cityList" :key='item.pinYin'>
                <p class="first-letter">{{ item.pinYin }}</p>
                <ul class="city-content row s_start wrap pt10">
                  <li class="city-item" v-for="(ele) in item.cities" :key='ele.cityCode' @click="chooseCity(ele)">
                    {{ ele.city }}
                  </li>
                </ul>
              </li>

            </ul>
            <div class="m20" />
          </div>

          <!-- 交易类型 -->
          <ul v-if="canShow.showTrade" class="trade-type" @mouseover="mapMouseout" @mouseout="mapMouseover">
            <li class="trade-item" v-for="(ele, index) in tradeType" :key='index' @click="chooseTrade(ele)">
              {{ ele.name }}
            </li>
          </ul>
        </div>

        <!-- 房源列表 -->
        <div style="position:absolute;top:100px;left:30px;"
          v-show="!canShow.showCity && !canShow.showTrade && !canShow.showSearch" @mouseover="mapMouseout"
          @mouseout="mapMouseover">
          <div class="title-width row flex1 sb_center" style="background-color: #ffffff;" @click="doShow('showHouse')">
            <label>查看<span style="color:#00ae66">全部</span>房源</label>
            <i v-if="!canShow.showHouse" class="el-icon-caret-bottom"></i>
            <i v-if="canShow.showHouse" class="el-icon-caret-top"></i>
          </div>
          <div v-if="canShow.showHouse" style="width:100%;height:1px;background-color:#f0f0f0" />
          <div v-if="canShow.showHouse" class='house-list city-list pb20' style='top:49px;width: 405px'>
            <ul v-if="currentEstate && currentEstate.guid" class="row sb_center mh10 ph5 mv10">
              <li>
                <router-link target="_blank" :to="`/houseEstateDetail/4?guid=${currentEstate.guid}`">
                  <p class="size16 mb5" style="color:#000;">
                    {{ currentEstate.name }}
                    <i class="el-icon-arrow-right"></i>
                  </p>
                  <p class="size12" style="color:#999;">
                    {{
                      (currentEstate.geographicAreaName ? currentEstate.geographicAreaName + " | " : '') +
                      `共${currentEstate.buildingNum}栋 ${currentEstate.roomNum}户 ` +
                      (currentEstate.completeYear ? `| ${currentEstate.completeYear}年建成` : '')
                    }}
                  </p>
                </router-link>
              </li>
              <li v-if="currentType.value == 2">
                <p class="size14 mb5">参考均价</p>
                <p class="size12 red">{{ currentEstate.price ? currentEstate.price + '元/m²' : '' }}</p>
              </li>
            </ul>
            <ul class="mh10 mt5 row s_center">
              <li class="ph5" @click="chooseSort(houseListSort[0], true)"
                :style="{ color: searchParams.sort == 0 ? '#00ae66' : '#000000' }">{{ houseListSort[0].name }}</li>
              <li class="ph5" @click="chooseSort(item)" v-for="(item, index) in houseListSort.slice(1)" :key="index">
                <span :style="{ color: currentSort.name == item.name ? '#00ae66' : '#000000' }">{{ item.name }}</span>
                <i v-if="searchParams.sort != item.decrement"
                  :style="{ color: currentSort.name == item.name ? '#00ae66' : '#999' }" class="el-icon-caret-top"></i>
                <i v-if="searchParams.sort == item.decrement" style="color:#00ae66;" class="el-icon-caret-bottom"></i>
              </li>
            </ul>
            <ul class="flex1 mh10 ph5 infinite-list" v-infinite-scroll="loadMore">
              <li v-for='(item) in houseList' v-show="houseList.length" :key='item.guid'>
                <house :item='item' :currentTab='currentType.value'></house>
              </li>
              <li v-show="!houseList.length && !loading">
                <p class="size14 whiteGray pv20 border-b center">暂无匹配房源</p>
              </li>

            </ul>
          </div>
        </div>

        <!-- 筛选条件 标题 -->
        <ul class='title-width flex s_center' @mouseover="mapMouseout" @mouseout="mapMouseover" :style="{
          width: (86 * 4 + (showClearFilter ? 86 : 0) + 'px'),
          position: 'absolute',
          top: '30px',
          left: '480px'
        }">
          <li v-show="filter.length" class="filter-item" @click="doShowFilter(0)">
            <p :style="{ color: (filterObj(0).color || '#000') }">{{ filterObj(0).name }}</p>
          </li>
          <li v-show="filter.length" class="filter-item" @click="doShowFilter(1)">
            <p :style="{ color: (filterObj(1).color || '#000') }">{{ filterObj(1).name }}</p>
          </li>
          <li v-show="filter.length" class="filter-item" @click="doShowFilter(2)">
            <p :style="{ color: (filterObj(2).color || '#000') }">{{ filterObj(2).name }}</p>
          </li>
          <li v-show="filter.length > 3" class="filter-item" @click="doShowFilter(3)">
            <p :style="{ color: (filterMore().color || '#000') }">{{ filterMore().name }}</p>
          </li>
          <li v-show="showClearFilter" class="filter-item" @click="clearFilterAll">
            <p :style="{ color: '#00ae66' }">清空筛选</p>
          </li>
        </ul>

        <!-- 筛选条件 视图 -->
        <ul v-if="showFilter !== -1" class="filter-view" @mouseover="mapMouseout" @mouseout="mapMouseover">
          <filter-view :source='filter' :index="showFilter" @updateFilter='updateFilter'></filter-view>
        </ul>

        <!-- 地铁 画图 -->
        <div :style="{
          position: 'absolute',
          top: '30px',
          left: clientWidth - 150 + 'px',
          'background-color': '#fff'
        }" @mouseover="mapMouseout" @mouseout="mapMouseover">
          <div class="draw-content">
            <div @click="changeDraw">
              <i v-if="!showDraw" class="el-icon-edit" style="width:100px;text-align:center;">画圈找房</i>
              <p v-if="showDraw" style="width:100px;text-align:center;">退出画圈找房</p>
            </div>
          </div>
        </div>
        <div v-if="polyPointArray && polyPointArray.length > 0" class='retry-draw' @click="retryDraw" :style="{
          position: 'absolute',
          top: '90px',
          left: clientWidth - 115 + 'px',
          'background-color': '#fff',
          width: '45px'
        }">
          <p><i class="el-icon-edit">重画</i></p>
        </div>
      </bm-control>

      <!-- 覆盖物 大区 -->
      <bm-overlay
        v-show="(10 < zoom && zoom < 15 && !isSetZoom && !showDraw && item && item.longitude && item.latitude)"
        v-for='(item) in region_2' :key='item.id' pane="labelPane" class="region region_2" @draw="({ el, BMap, map }) => {
          const pixel = map.pointToOverlayPixel(new BMap.Point(item.longitude, item.latitude))
          el.style.left = pixel.x - 60 + 'px'
          el.style.top = pixel.y - 20 + 'px'
        }" @mouseover.native="active = true" @mouseleave.native="active = false">
        <div style="color:#fff;" @click="setZoom({}, 15, item)">
          <div class="size14 mb5">{{ item.name }}</div>
          <div class="size12" v-if="currentType.value == 1">{{ item.rentCount + '套' }}</div>
          <div class="size12" v-if="currentType.value == 2">{{ item.saleCount + '套' }}</div>
        </div>
      </bm-overlay>


      <!-- 覆盖物 片区 -->
      <bm-overlay v-show="(14 < zoom && zoom < 17 && !isSetZoom && !showDraw)" v-for='(item) in region_3'
        :key='item.uuid' pane="labelPane" class="region region_3 row c_center" @draw="({ el, BMap, map }) => {
          const pixel = map.pointToOverlayPixel(new BMap.Point(item.longitude, item.latitude))
          el.style.left = pixel.x - 60 + 'px'
          el.style.top = pixel.y - 20 + 'px'
        }" @mouseover.native="active = true" @mouseleave.native="active = false">
        <div style="color:#fff;" @click="setZoom({}, 17, item)">
          <div class="size14 mb5">{{ item.name }}</div>
          <div class="size12" v-if="currentType.value == 1">{{ item.rentCount + '套' }}</div>
          <div class="size12" v-if="currentType.value == 2">{{ item.saleCount + '套' }}</div>
        </div>
      </bm-overlay>

      <!-- 覆盖物 楼盘 -->
      <bm-overlay
        v-show="(16 < zoom && !isSetZoom && !showDraw) && ((currentType.value == 1 && item.rentCount) || (currentType.value == 2 && item.saleCount))"
        v-for='(item) in regionByType' :key='item.uuid' pane="labelPane"
        :class="{ 'region': true, 'region_4': true, 'choose-region': currentEstate && currentEstate.guid == item.guid }"
        @draw="({ el, BMap, map }) => {
          const pixel = map.pointToOverlayPixel(new BMap.Point(item.lng, item.lat))
          el.style.left = pixel.x - 60 + 'px'
          el.style.top = pixel.y - 20 + 'px'
        }" @mouseover.native="active = true" @mouseleave.native="active = false">
        <div class="row c_center" @click="loadHouseByEstateGuid(item)" style="color:#fff">
          <div class="size12 max-width">{{ item.name }}</div>
          <div v-if="(currentType.value == 1 && item.rentCount) || (currentType.value == 2 && item.saleCount)"
            class="mh5">|
          </div>
          <div class="size12 max-width" v-if="currentType.value == 1">{{ item.rentCount ? item.rentCount + '套' : "" }}
          </div>
          <div class="size12 max-width" v-if="currentType.value == 2">{{ item.saleCount ? item.saleCount + '套' : "" }}
          </div>
        </div>
      </bm-overlay>

      <!-- 画圈 -->
      <bm-overlay
        v-show="(16 < zoom && !isSetZoom && showDraw) && ((currentType.value == 1 && item.rentCount) || (currentType.value == 2 && item.saleCount))"
        v-for='(item) in region_6' :key='item.guid' pane="labelPane"
        :class="{ 'region': true, 'region_4': true, 'choose-region': currentEstate && currentEstate.guid == item.guid }"
        @draw="({ el, BMap, map }) => {
          const pixel = map.pointToOverlayPixel(new BMap.Point(item.lng, item.lat))
          el.style.left = pixel.x - 60 + 'px'
          el.style.top = pixel.y - 20 + 'px'
        }" @mouseover.native="active = true" @mouseleave.native="active = false">
        <div class="row c_center" @click="loadHouseByEstateGuid(item)" style="color:#fff">
          <div class="size12 max-width">{{ item.name }}</div>
          <div v-if="(currentType.value == 1 && item.rentCount) || (currentType.value == 2 && item.saleCount)"
            class="mh5">|
          </div>
          <div class="size12 max-width" v-if="currentType.value == 1">{{ item.rentCount ? item.rentCount + '套' : "" }}
          </div>
          <div class="size12 max-width" v-if="currentType.value == 2">{{ item.saleCount ? item.saleCount + '套' : "" }}
          </div>
        </div>
      </bm-overlay>
    </baidu-map>
  </div>
</template>

<script>
import house from "./components/house";
import filterView from "./components/filter";
import HouseServer from "./../../services/houseServer";
import { mapState } from "vuex";
import { nanoid } from "nanoid";
import tool from "../../utils/tool";
import Constant from "@/common/Constant"


export default {
  name: "setting",
  props: {},
  data() {
    return {
      isSourceERP: false,
      region2Id: 0,
      BMap: null,
      baiduMap: null,
      isSetZoom: false,
      loading: true,
      center: {},
      zoom: 14,
      cityList: [],
      currentType: {
        name: "二手房",
        value: 2
      },
      tradeType: [
        {
          name: "二手房",
          value: 2
        },
        {
          name: "租房",
          value: 1
        }
      ],
      searchKey: "",
      canShow: {
        showSearch: false,
        showCity: false,
        showTrade: false,
        showHouse: false
      },
      currentSort: {
        name: "综合排序",
        value: 0
      },
      houseListSort: [
        {
          name: "综合排序",
          value: 0
        },
        {
          name: "总价",
          increment: "2",
          decrement: 3
        },
        {
          name: "单价",
          increment: 4,
          decrement: 10
        },
        {
          name: "面积",
          increment: 11,
          decrement: 5
        }
      ],
      houseList: [],
      region_2: [], // 大区
      region_3: [], // 片区
      region_3_data: [],
      region_4: [], // 街道
      region_4_data: [],
      region_5: [], // 搜索
      region_6: [], // 画圈
      currentEstate: {},
      searchParams: {
        cityCode: "",
        estateGuid: "",
        tradeType: "",
        page: 1,
        itemsPerPage: 10,
        sort: 0
      },
      showFilter: -1,
      filter: [],

      hasMore: true,

      showDraw: false, // 开启画图模式
      isMouseDown: false, // 处于画图中
      isInDrawing: false,
      polyPointArray: null,
      lastPolygon: null,
      lastPolyLine: null,

      aaaa: ""
    };
  },
  watch: {},
  components: {
    house,
    "filter-view": filterView
  },
  computed: {
    filterObj() {
      return index => {
        if (!this.filter.length) {
          return {};
        }
        const obj = {};
        const { value, name } = this.filter[index];
        if (value) {
          if (value instanceof Array) {
            switch (value.length) {
              case 0:
                obj.name = name;
                break;
              case 1:
                obj.name = value[0].name;
                obj.color = "#00ae66";
                break;
              default:
                obj.name = "多选";
                obj.color = "#00ae66";
            }
          } else {
            obj.name = value.name;
            obj.color = "#00ae66";
          }
        } else {
          obj.name = name;
        }
        return obj;
      };
    },
    filterMore() {
      return () => {
        if (!this.filter.length) {
          return {};
        }
        const result = this.filter.slice(3).some(item => {
          if (item.value instanceof String && item.value) {
            return true;
          } else {
            if (item.value.length > 0) return true;
          }
        });
        return {
          color: result ? "#00ae66" : "",
          name: result ? "多选" : "更多"
        };
      };
    },
    showClearFilter() {
      if (!this.filter.length) {
        return false;
      }
      const result = this.filter.some(item => {
        if (item.value instanceof String && item.value) {
          return true;
        } else {
          if (item.value.length > 0) return true;
        }
      });
      return result;
    },
    clientWidth() {
      return document.documentElement.clientWidth;
    },
    regionByType() {
      if (this.searchKey) {
        return this.region_5;
      }
      return this.region_4;
    },
    ...mapState(["location", "companyConfig"]),
    whiteLogo() {
      return this.companyConfig.whiteLogo
    }
  },
  methods: {
    // 动态优化展示
    mapViewChange() {
      //   const zoom = this.zoom;
      //   // 片区
      //   if (14 < zoom && zoom < 18) {
      //     const result = this.region_3_data.filter(item => {
      //          let point3 = new this.BMap.Point(item.lng, item.lat)
      //         // this.baiduMap
      //         //   .getBounds()
      //         //   .containsPoint(new this.BMap.Point(item.longitude, item.latitude))
      //       if (point3) {
      //         item.uuid = nanoid();
      //         return item;
      //       }
      //     });
      //     this.region_3 = result;
      //   }
      //   // 街道
      //   if (17 < zoom) {
      //     const result = this.region_4_data.filter(item => {
      //         let point4 = new this.BMap.Point(item.lng, item.lat)
      //         //  this.baiduMap
      //         //   .getBounds()
      //         //   .containsPoint(new this.BMap.Point(item.lng, item.lat))
      //       if (point4&&this.baiduMap.getBounds().containsPoint(point4)) {
      //         item.uuid = nanoid();
      //         return item;
      //       }
      //     });
      //     this.region_4 = result;
      //   }
    },

    mapMouseout() {
      if (
        this.canShow.showCity ||
        this.canShow.showHouse ||
        this.showFilter > -1
      ) {
        this.baiduMap.disableScrollWheelZoom();
      }
    },
    mapMouseover() {
      if (
        this.canShow.showCity ||
        this.canShow.showHouse ||
        this.showFilter > -1
      ) {
        this.baiduMap.enableScrollWheelZoom(true);
      }
    },

    handler({ BMap, map }) {
      this.BMap = BMap;
      this.baiduMap = map;
      this.baiduMap.enableScrollWheelZoom(true);

      const { cityCode, cityName, latitude, longitude } = this.location;
      this.center = {
        lng: longitude,
        lat: latitude,
        city: cityName,
        cityCode
      };
      this.zoom = 14;
      this.isSourceERP ? this.getgetAllCityTreeCompany() : this.loadCityList();

      this.init();

    },

    init(house = "", region = "", area = "", street = "") {
      this.loading = true;
      if (!region && !area) {
        this.loadHouseParams();
      }
      this.searchParams = {
        ...this.searchParams,
        cityCode: this.center.cityCode,
        tradeType: this.currentType.value,
        page: 1,
        itemsPerPage: 10,
        sort: 0
      };
      if (region) {
        const { regionLevel, ...other } = region
        this.searchParams = {
          ...this.searchParams,
          ...other
        }
      }
      try {
        this.loadHouseList(this.searchParams);
        if (!this.canShow.showHouse) {
          this.doShow("showHouse");
        }
        // 大区
        this.loadAreaRegion(
          region || {
            area: [],
            cityCode: this.center.cityCode,
            decoration: "",
            houseType: "",
            price: [],
            regionLevel: 2,
            room: [],
            tag: [],
            tradeType: this.currentType.value
          }
        );

        // 片区
        this.loadAreaRegion(
          area || {
            area: [],
            cityCode: this.center.cityCode,
            decoration: "",
            houseType: "",
            price: [],
            regionLevel: 3,
            room: [],
            tag: [],
            tradeType: this.currentType.value
          },
          true
        );

        // 街道
        this.loadStreetList(
          {
            regionCode: "",
            cityCode: this.center.cityCode,
            tradeType: this.currentType.value,
            estateName: ""
          },
          this.searchKey || false
        );
      } catch (error) {
        this.loading = false
        console.log('init>>>error>>', error)
      }

    },

    setZoom({ target }, count = 0, item = {}) {
      if (this.baiduMap) {
        let mapBounds = this.baiduMap.getBounds()
        console.log('mapBounds>>', mapBounds)
        console.log('mapBounds>>西南>>', mapBounds.getSouthWest())
        console.log('mapBounds>>东北>>', mapBounds.getNorthEast())
      }

      if (count) {
        this.isSetZoom = true;
        this.zoom = count;
        if (!this.canShow.showHouse) {
          this.doShow("showHouse");
        }
        this.center = {
          ...this.center,
          lng: item.longitude,
          lat: item.latitude
        };
        this.isSetZoom = false;
      } else {
        this.zoom = target.getZoom();
        this.mapViewChange();
        console.log("当前zoom :>> ", this.zoom);
      }
      if (item.guid) {
        console.log('loadHouseByRegqq>>item>>', item)
        this.loadHouseByRegqq(item)
      }
    },

    // 街道
    doSearch() {
      this.time && clearTimeout(this.time);
      if (this.searchKey) {
        this.time = setTimeout(() => {
          const result = this.region_4_data.filter(item => {
            if (
              item.name &&
              item.name.indexOf(this.searchKey) > -1 &&
              (this.currentType.value == 2 ? item.saleCount : item.rentCount)
            ) {
              {
                item.uuid = nanoid();
                return item;
              }
            }
          });
          if (result.length) {
            // 设置 定位
            this.center = {
              ...this.center,
              lng: result[0].lng,
              lat: result[0].lat
            };
            // 设置 缩放级别
            this.zoom = 18;
            // 设置 数据
            this.region_5 = result;
            this.canShow.showSearch = true;
          } else {
            this.region_5 = [];
            this.msg && this.msg.close();
            this.msg = this.$message({
              type: "warning",
              message: "~对不起，未查询到相关结果~"
            });
          }
        }, 500);
      } else {
        this.clearSearch();
      }
    },

    clickSearch(item) {
      this.zoom = 18;
      this.center = {
        ...this.center,
        lng: item.lng,
        lat: item.lat
      };
      this.canShow.showSearch = false;
      this.loadHouseByEstateGuid(item);
    },

    clearSearch() {
      this.searchKey = "";
      this.canShow.showSearch = false;
      this.canShow.showHouse = false;
      this.region_5 = [];
      this.mapViewChange();
    },
    //
    async getgetAllCityTreeCompany() {
      const [err, { data }] = await tool.to(HouseServer.getAllCityTreeCompany());
      if (err) {
        console.log("getAllCityTree :>>", err);
        return;
      }
      // console.log("getAllCityTreeCompany :>>", data);
      this.cityList = data.cityTree;
      //   this.allCity = data.cityTree;
    },
    // 城市列表
    async loadCityList() {
      try {
        const { data } = await HouseServer.getAllCityTree();
        this.cityList = data.cityTree;
      } catch (e) {
        console.log("loadCityList :>> ", e);
      }
    },
    // 获取当前公司城市列表
    async getCompanyCitys() {
      const [err, { data: { citys } }] = await tool.to(HouseServer.getCompanyConfig());
      if (err) {
        console.log("getCompanyConfig :>>", err);
        return
      }
      const cityList = [{
        pinYin: '',
        cities: JSON.parse(citys) || []
      }]
      this.cityList = cityList;
    },

    // 参数
    async loadHouseParams() {
      try {
        const { data = {} } = await HouseServer.loadHouseParams({
          cityCode: this.center.cityCode,
          tradeType: this.currentType.value
        });
        // 处理数据
        const result = [
          {
            name: "价格",
            title: "价格（万）",
            unit: "万",
            value: "",
            selfValue: {
              min: "",
              max: ""
            },
            option: [],
            key: "salePrice",
            type: "multiple-input"
          },
          {
            name: "户型",
            value: "",
            option: [],
            key: "room",
            type: "multiple"
          },
          {
            name: "面积",
            title: "面积（m²）",
            value: "",
            option: [],
            key: "area",
            type: "multiple"
          },
          {
            name: "装修",
            value: "",
            option: [],
            key: "decorate",
            type: "single"
          },
          {
            name: "朝向",
            value: "",
            option: [],
            key: "orientation",
            type: "single"
          },
          {
            name: "类型",
            value: "",
            option: [],
            key: "houseType",
            type: "single"
          },
          {
            name: "标签",
            value: "",
            option: [],
            key: "tag",
            type: "multiple"
          }
        ];
        result.forEach(item => {
          item.option = data[item.key] || [];
        });
        if (this.currentType.value != "2") {
          result[0].option = [];
          result[0].name = "租价";
          result[0].title = "价格（元/月）";
          result[0].unit = "元/月";
        }
        this.filter = result;
      } catch (e) {
        console.log("loadCityList :>> ", e);
      }
    },

    // 触底加载
    loadMore() {
      if (this.loading || !this.hasMore) {
        return
      }
      this.searchParams.page++;
      this.loadHouseList(this.searchParams);
    },

    // 房源
    async loadHouseList(params) {
      try {
        const { data } = await HouseServer.loadHouseList(params);
        if (params.page == 1) {
          this.houseList = data;
        } else {
          this.houseList = [...this.houseList, ...data];
        }
        if (data.length < params.itemsPerPage) {
          this.hasMore = false
        } else {
          this.hasMore = true
        }
      } catch (e) {
        console.log("loadHouseList :>> ", e);
      }
    },

    // 大区/片区
    async loadAreaRegion(params, type = false) {
      try {
        const { data = [] } = await HouseServer.loadAreaRegion(params);
        const result = data.filter(
          item =>
            item.latitude &&
            item.longitude &&
            (item.saleCount || item.rentCount)
        );
        if (type) {
          this.region_3_data = result;
          this.region_3 = result;
        } else {
          this.region_2 = result.map(item => ({ ...item, id: this.region2Id++ }))

        }
      } catch (e) {
        console.log("loadAreaRegion :>> ", e);
      } finally {
        this.loading = false;
      }
    },

    // 小区
    async loadStreetList(params, isSearch = false) {
      try {
        const { data = [] } = await HouseServer.loadStreetList(params);
        const result = data.filter(item => {
          if (
            item.lat &&
            item.lng &&
            item.name &&
            (item.saleCount || item.rentCount)
          ) {
            item.uuid = nanoid();
            return item;
          }
        });
        if (isSearch) {
          if (result.length) {
            // 设置 定位
            this.center = {
              ...this.center,
              lng: result[0].lng,
              lat: result[0].lat
            };
            // 设置 缩放级别
            this.zoom = 18;
            // 设置 数据
            this.region_5 = result;
            this.doShow("showSearch");
            this.loading = false;
          } else {

            this.loading = false;
            this.$message({
              type: "warning",
              message: "~对不起，未查询到相关结果~"
            });
          }
        } else {
          this.region_4_data = result;
          this.region_4 = result;
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        console.log("loadAreaRegion :>> ", e);
      }
    },

    doShow(name) {
      if (name != "showHouse" || (name && this.canShow.showHouse)) {
        this.doHiddenFilter();
      }

      if (name) {
        this.canShow[name] = !this.canShow[name];
        if (!this.canShow[name]) {
          this.baiduMap.enableScrollWheelZoom(true);
        }
      }

      for (const key of Object.keys(this.canShow)) {
        if (key != name) {
          this.canShow[key] = false;
        }
      }

      this.canShow = { ...this.canShow };
    },

    chooseCity(city) {
      // 切换城市
      this.center = city;
      // 关闭选择
      this.doShow("showCity");
      // 初始化 数据
      this.init();
    },

    chooseTrade(trade) {
      // 切换交易
      this.currentType = trade;
      // 关闭选择
      this.doShow("showTrade");
      // 加载房源
      this.init();
    },

    chooseSort(item, single = false) {
      this.currentSort = item;
      if (single) {
        this.searchParams.sort = item.value;
      } else {
        const { sort } = this.searchParams;
        if (sort == item.increment) {
          this.searchParams.sort = item.decrement;
        } else {
          this.searchParams.sort = item.increment;
        }
      }
      this.searchParams.page = 1;
      this.loadHouseList(this.searchParams);
    },

    doShowFilter(index) {
      if (index != this.showFilter) {
        this.showFilter = index;
      } else {
        this.doHiddenFilter();
      }
    },

    doHiddenFilter() {
      this.showFilter = -1;
      this.baiduMap.enableScrollWheelZoom(true);
    },

    updateFilter({ filter: nextFilter = {}, operate = "" }) {

      const obj = {
        price: [],
        area: [],
        room: [],
        decoration: "",
        orientation: "",
        houseType: "",
        tag: []
      };
      if (operate) {
        switch (operate) {
          case "取消":
            this.doHiddenFilter();
            break;
          case "确定":
            this.doHiddenFilter();
            if (!this.canShow.showHouse) {
              this.doShow("showHouse");
            }
            // 加载 房源 2级 3级 区域 街道
            this.filter.forEach(item => {
              if (item.key == "salePrice") {
                if (
                  item.selfValue &&
                  "min" in item.selfValue &&
                  "max" in item.selfValue
                ) {
                  const { min, max } = item.selfValue || {};
                  if (min && max) {
                    obj.price.push(min + "-" + max);
                  }
                }
                item.value &&
                  item.value.length &&
                  item.value.forEach(ele => {
                    obj.price.push(ele.value);
                  });
              }
              if (item.key == "room") {
                item.value &&
                  item.value.length &&
                  item.value.forEach(ele => {
                    obj.room.push(ele.value);
                  });
              }
              if (item.key == "area") {
                item.value &&
                  item.value.length &&
                  item.value.forEach(ele => {
                    obj.area.push(ele.value);
                  });
              }
              if (item.key == "decorate") {
                obj.decorate = item.value.value || "";
              }
              if (item.key == "orientation") {
                obj.orientation = item.value.value || "";
              }
              if (item.key == "houseType") {
                item.value &&
                  item.value.length &&
                  item.value.forEach(ele => {
                    obj.houseType.push(ele.value);
                  });
              }
              if (item.key == "tag") {
                item.value &&
                  item.value.length &&
                  item.value.forEach(ele => {
                    obj.tag.push(ele.value);
                  });
              }
            });
            this.init(
              "",
              {
                ...obj,
                regionLevel: 2,
                cityCode: this.center.cityCode,
                tradeType: this.currentType.value
              },
              {
                ...obj,
                regionLevel: 3,
                cityCode: this.center.cityCode,
                tradeType: this.currentType.value
              }
            );
          // break;
        }
      } else {
        this.filter = [...nextFilter];
      }
    },

    clearFilterAll() {
      this.filter.forEach(item => {
        if (item.type == "multiple-input") {
          item.value = [];
          item.selfValue = {};
        } else if (item.type == "multiple") {
          item.value = [];
        } else {
          item.value = "";
        }
      });
      this.currentEstate = {}
      this.searchParams.room = []//不知道为啥清不掉
      this.searchParams.page = 1;
      this.searchParams.sort = 0;
      this.searchParams.area2 = ''
      this.searchParams.area3 = ''

      this.searchParams.estateGuid = '';
      this.filter = [...this.filter];
      this.doHiddenFilter();
      this.init();
    },

    loadHouseByEstateGuid(item) {
      this.currentEstate = item;
      this.searchParams.page = 1;
      this.searchParams.sort = 0;
      this.searchParams.area2 = ''
      this.searchParams.area3 = ''
      this.searchParams.estateGuid = item.guid;
      this.loadHouseList(this.searchParams);
      if (!this.canShow.showHouse) {
        this.doShow("showHouse");
      }
    },
    loadHouseByRegqq(item) {
      this.searchParams.page = 1;
      this.searchParams.sort = 0;
      this.searchParams.estateGuid = '';
      if (item.level == 2) {
        this.searchParams.area2 = item.value
        this.searchParams.area3 = ''
      } else if (item.level == 3) {
        this.searchParams.area2 = ''
        this.searchParams.area3 = item.value
      }
      this.loadHouseList(this.searchParams);
      if (!this.canShow.showHouse) {
        this.doShow("showHouse");
      }
    },

    changeDraw() {
      if (this.showDraw) {
        // 退出画图
        this.baiduMap.setDefaultCursor("default");
        this.baiduMap.enableDragging();
        this.baiduMap.enableScrollWheelZoom(true);
        this.baiduMap.enableDoubleClickZoom();
        this.baiduMap.enableKeyboard();

        // this.baiduMap.clearOverlays();
        this.baiduMap.removeOverlay(this.lastPolygon); // 清除画的圈
        this.baiduMap.removeOverlay(this.lastPolyLine); // 清楚画的线
        this.polyPointArray = [];
        this.region_6 = [];
        this.lastPolyLine = null;
        this.lastPolygon = null;

        this.currentEstate = null;
        this.searchParams.page = 1;
        this.searchParams.sort = 0;
        this.searchParams.area2 = ''
        this.searchParams.area3 = ''
        this.searchParams.estateGuid = "";
        this.houseList = [];
        if (!this.canShow.showHouse) {
          this.doShow("showHouse");
        }

        this.showDraw = false;

        this.isInDrawing = false;
        this.isMouseDown = false;
        // this.zoom = 15
      } else {
        // 画图模式
        // this.zoom = 17
        if (this.zoom > 16) {
          this.baiduMap.setDefaultCursor("crosshair");
          this.baiduMap.disableDragging();
          this.baiduMap.disableScrollWheelZoom();
          this.baiduMap.disableDoubleClickZoom();
          this.baiduMap.disableKeyboard();

          this.baiduMap.removeOverlay(this.lastPolygon); // 清除画的圈
          this.baiduMap.removeOverlay(this.lastPolyLine); // 清楚画的线
          this.polyPointArray = [];
          this.region_6 = [];
          this.lastPolyLine = null;
          this.lastPolygon = null;

          this.currentEstate = null;
          this.searchParams.page = 1;
          this.searchParams.sort = 0;
          this.searchParams.estateGuid = "";
          this.searchParams.area2 = ''
          this.searchParams.area3 = ''
          this.houseList = [];

          if (!this.canShow.showHouse) {
            this.doShow("showHouse");
          }

          this.showDraw = true;

          this.searchKey = "";
          this.isInDrawing = false;
          this.isMouseDown = false;

          this.showFilter = -1; // 关闭 筛选
          this.canShow.showCity = false; // 关闭 城市
          this.canShow.showTrade = false; // 关闭 交易
          this.canShow.showHouse = false; // 关闭 房源
        } else {
          this.selfOption && this.selfOption.close();
          this.selfOption = this.$message({
            type: "warning",
            message: "~请放大地图重试~"
          });
        }
      }
    },

    retryDraw() {
      // 重画
      this.showDraw = true;
      this.isInDrawing = false;
      this.isMouseDown = false;

      this.baiduMap.setDefaultCursor("crosshair");
      this.baiduMap.disableDragging();
      this.baiduMap.disableScrollWheelZoom();
      this.baiduMap.disableDoubleClickZoom();
      this.baiduMap.disableKeyboard();

      this.baiduMap.removeOverlay(this.lastPolygon); // 清除画的圈
      this.baiduMap.removeOverlay(this.lastPolyLine); // 清楚画的线
      this.polyPointArray = [];
      this.region_6 = [];
      this.lastPolyLine = null;
      this.lastPolygon = null;

      this.currentEstate = null;
      this.searchParams.page = 1;
      this.searchParams.sort = 0;
      this.searchParams.estateGuid = "";
      this.searchParams.area2 = ''
      this.searchParams.area3 = ''
      this.houseList = [];
      if (!this.canShow.showHouse) {
        this.doShow("showHouse");
      }
    },

    // 地图画圈控制
    mapMouseDown() {
      if (this.showDraw) {
        this.isInDrawing = true;
      }
    },

    // 地图画圈控制
    mapMouseUp() {
      if (this.showDraw) {
        this.baiduMap.setDefaultCursor("default");
        this.baiduMap.enableDragging();
        this.baiduMap.enableScrollWheelZoom(true);
        this.baiduMap.enableDoubleClickZoom();
        this.baiduMap.enableKeyboard();

        this.isMouseDown = true; // 画圈结束
      }
    },

    drawMap({ point }) {
      // 画图模式 + 正在画图
      if (this.showDraw && this.isInDrawing) {
        // 画线结束 首尾相连
        if (this.isMouseDown) {
          this.lastPolygon && this.baiduMap.removeOverlay(this.lastPolygon); // 清除画的圈
          this.isInDrawing = false; // 结束画图
          this.isMouseDown = true; // 结束画圈
          const polygon = new this.BMap.Polygon(this.polyPointArray, {
            strokeColor: "#00ae66",
            strokeOpacity: 1,
            fillColor: "#00ae66",
            fillOpacity: 0.3,
            enableClicking: false
          });
          this.lastPolygon = polygon;
          this.baiduMap.addOverlay(polygon);
          this.buildData(polygon);
        } else {
          this.polyPointArray.push(point);
          // 除去上次的画线
          this.lastPolyLine && this.baiduMap.removeOverlay(this.lastPolyLine);
          // 根据已有的路径数组构建画出的折线
          const polylineOverlay = new this.BMap.Polyline(this.polyPointArray, {
            strokeColor: "#00ae66",
            strokeOpacity: 1,
            enableClicking: false
          });
          // 添加新的画线到地图上
          this.baiduMap.addOverlay(polylineOverlay);
          // 更新上次画线条
          this.lastPolyLine = polylineOverlay;
        }
      }
    },

    buildData(polygon) {
      // 得到多边形的点数组
      const pointArray = polygon.getPath();
      //   console.log('多边形坐标集合：',JSON.stringify(pointArray) )
      // 获取多边形的外包矩形
      // const bound = polygon.getBounds();
      // 在多边形内的点的数组
      // const pointInPolygonArray = [];
      // 计算每个点是否包含在该多边形内
      const result = this.region_4_data.filter(item =>
        this.isPointInPolygon({ lng: item.lng, lat: item.lat }, pointArray)
      );
      if (
        result.length &&
        !(this.currentEstate && "guid" in this.currentEstate)
      ) {
        this.region_6 = result;
        this.loadHouseByEstateGuid(result[0]);
      } else {
        if (!result.length) {
          this.$message({
            type: "info",
            message: "~该区域内暂无相关房源~"
          });
        }
      }
    },

    isPointInPolygon(pt, poly) {
      for (var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i)
        ((poly[i].lat <= pt.lat && pt.lat < poly[j].lat) ||
          (poly[j].lat <= pt.lat && pt.lat < poly[i].lat)) &&
          pt.lng <
          ((poly[j].lng - poly[i].lng) * (pt.lat - poly[i].lat)) /
          (poly[j].lat - poly[i].lat) +
          poly[i].lng &&
          (c = !c);
      return c;
    }
  },
  created() {

    this.isSourceERP = this.$route.query.isSourceERP ? true : false
  }
};
</script>

<style scoped lang="less">
.loading {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  transform: scale(5);
}

.bm-view {
  width: 100vw;
  height: calc(100vh - 38px);
}

.title-width {
  width: 375px;
  border-radius: 5px;
  padding: 0 15px;
  height: 48px;
  background-color: #ffffff;
}

.logo {
  width: 30px;
  height: 30px;
  display: block;
  background-color: #00ae66;
  border-radius: 30px;
}

.input {
  display: block;
  width: 130px;
  padding: 5px 2px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid #40a9ff;
  }
}

.house-list {
  width: 375px;
  background-color: #ffffff;
  height: calc(100vh - 350px);
  padding: 0 15px;
  margin-bottom: 100px;
  overflow: auto;
}

.trade-type {
  position: absolute;
  top: 70px;
  left: 150px;
  background-color: #ffffff;
  width: 128px;
  padding: 10px 24px 24px 24px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 8%);
}

.trade-item {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #222;
  text-align: left;
  line-height: 22px;
  margin-top: 20px;

  &:hover {
    color: #00ae66;
  }
}

.sort-item {
  padding: 10px 16px;
  font-size: 12px;
  color: #222;
  margin-right: 5px;
}

.city-name {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.city-list-cityCard {
  height: calc(100vh - 350px);
}

.city-list {
  padding: 0;
  width: 415px;
  position: absolute;
  top: 70px;

  .first-letter {
    margin-top: 16px;
    background: #f8f8f8;
    height: 22px;
    padding-left: 24px;
    line-height: 22px;
  }

  .city-item {
    margin: 5px;
    width: 100px;
    height: 40px;

    &:hover {
      color: #00ae66;
    }
  }

  .city-content {
    padding-left: 24px;
  }
}

.filter-item {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 86px;
}

.filter-view {
  background: #fff;
  border-radius: 4px;
  max-height: 500px;
  width: 505px;
  overflow-y: auto;
  position: absolute;
  left: 480px;
  top: 100px;
  padding: 20px 24px 20px;
}

.draw-content {
  font-size: 15px;
  padding: 12px 15px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.retry-draw {
  border-radius: 4px;
  font-size: 15px;
  background-color: #fff;
  position: absolute;
  padding: 10px 20px;
  top: 90px;
  right: 30px;
}

.region {
  background: rgba(0, 174, 102, 0.8);
  overflow: hidden;
  box-shadow: 0 0 2px #00ae66;
  color: #fff;
  text-align: center;
  padding: 15px;
  position: absolute;

  &:hover {
    background-color: #ff9000;
    box-shadow: 0 0 3px #ff9000;
    transform: scaleY (1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
  }
}

.region_2 {
  width: 120px;
  border-radius: 50px;
}

.region_3 {
  width: 65px;
  height: 65px;
  border-radius: 65px;
}

.region_4 {
  width: 145px;
  border-radius: 15px;
  padding: 5px;
  background-color: #00ae66;
}

.max-width {
  max-width: max-content;
}

.choose-region {
  background: #fa5741;
  box-shadow: 0 0 2px #fa5741;

  &:hover {
    background-color: #fa5741;
    box-shadow: 0 0 3px #fa5741;
  }
}
</style>